import "styles/pages/home-page.scss";

import React from "react";
import { graphql } from "gatsby";

import Layout from "components/Layout";

import SearcherProvider from "context/searcher";

import { Apartments, Invest, Map, Hero } from "page_components/Home";
import Investments from "page_components/Home/Investments";

const Home = ({ data }) => {
	const allInvestments = data.allInvestments.nodes;
	const investmentsForHomeMap = data.investmentsForHomeMap.nodes;
	const featured_investments =
		data.featuredInvestments.nodes?.[0]?.homePage?.attractiveApartments;

	const seo = data?.homePage?.seo;
	const schema = JSON.parse(data.homePage.seo.schema.raw);

	const spain_investments = allInvestments.filter(
		({ investments: { category } }) => category.includes("spain")
	);
	const mountains_investments = allInvestments.filter(
		({ investments: { category } }) => category.includes("mountains")
	);
	const lake_investments = allInvestments.filter(
		({ investments: { category } }) => category.includes("lake")
	);
	const sea_investments = allInvestments.filter(
		({ investments: { category } }) => category.includes("sea")
	);

	return (
		<Layout
			location="Strona główna"
			seo={{ title: seo?.title, description: seo?.metaDesc, schema }}
			popup={data?.homePage?.homePage?.popupImage?.sourceUrl}
			popupLink={data?.homePage?.homePage?.popupLink}
		>
			<SearcherProvider>
				<Hero
					show_searcher={true}
					slider={data.homePage.homePage.slide}
				/>
				<div className="invest-apartment">
					<Invest />
					<Apartments data={featured_investments} />
				</div>
				<Map markers={investmentsForHomeMap} />
				<Investments
					spain_investments={spain_investments}
					mountains_investments={mountains_investments}
					lake_investments={lake_investments}
					sea_investments={sea_investments}
				/>
			</SearcherProvider>
		</Layout>
	);
};

export default Home;

export const query = graphql`
	query homeInvestments {
		featuredInvestments: allWpPage(filter: { id: { eq: "cG9zdDoxMDY3" } }) {
			nodes {
				id
				homePage {
					attractiveApartments {
						... on WpInvestment {
							title
							uri
							slug
							investments {
								shortLocation
								mainTitle
								mainImg {
									sourceUrl
								}
								subtitle
								labelState
								labelText
							}
						}
					}
				}
			}
		}
		investmentsForHomeMap: allWpInvestment(
			sort: { fields: investments___ready, order: DESC }
			filter: {
				investments: {
					hideInvestment: { eq: true }
					ready: { eq: null }
				}
			}
		) {
			nodes {
				title
				id
				uri
				slug
				investments {
					category
					backPercentage
					subdivisionModel
					homePick
					ready
					contactLength
					deadline
					investLogo {
						sourceUrl
						altText
					}
					mainImg {
						sourceUrl
					}
					mainTitle
					priceMin
					priceMax
					currency
					vacationsDaysCustom
					subtitle
					shortLocation
					surfacemin
					locationLng
					locationLat
					parking
					spa
					restaurant
					skiRoom
					reception
					gym
					conferenceRoom
					bikeRoom
					bar
					poolIn
					poolOut
					terrace
				}
			}
		}
		allInvestments: allWpInvestment(
			sort: { fields: investments___position }
			filter: { investments: { homePick: { eq: true } } }
		) {
			nodes {
				title
				id
				uri
				slug
				investments {
					category
					homePick
					subdivisionModel
					backPercentage
					contactLength
					deadline
					investLogo {
						sourceUrl
						altText
					}
					mainImg {
						sourceUrl
					}
					mainTitle
					priceMin
					currency
					subtitle
					shortLocation
					surfacemin
					labelState
					labelText
					position
				}
			}
		}
		homePage: wpPage(id: { eq: "cG9zdDoxMDY3" }) {
			homePage {
				slide {
					slideText
					slideTitle
					slideImage {
						altText
						sourceUrl
					}
				}
				popupImage {
					sourceUrl
					localFile {
						publicURL
					}
				}
				popupLink
			}
			seo {
				title
				metaDesc
				schema {
					raw
				}
				opengraphTitle
				opengraphDescription
				opengraphType
				opengraphUrl
				opengraphImage {
					sourceUrl
				}
			}
		}
	}
`;
