import "./styles.scss";
import React from "react";

import InvestmentsSlider from "components/InvestmentsSlider";

import SpainBackground from "images/spain-bg.png";

import SpainIcon from "icons/investments/spain.svg";
import MountainsIcon from "icons/investments/mountains.svg";
import SeaIcon from "icons/investments/sea.svg";
import MazuryIcon from "icons/investments/mazury.svg";

const mainClass = "home-investments";

const Investments = ({
  spain_investments,
  mountains_investments,
  lake_investments,
  sea_investments,
}) => {
  let formatted_mountains_investments = [...mountains_investments];

  const investments_data = [
    {
      background_image: SpainBackground,
      header_data: {
        icon: <SpainIcon />,
        location: "w Hiszpanii",
        href: "/investments/?category=spain",
      },
      theme: "orange",
      data: spain_investments,
    },
    {
      background_color: "#FFFFFF",
      header_data: {
        icon: <MountainsIcon />,
        location: "w Górach",
        href: "/investments/?category=mountains",
      },
      theme: "navy",
      items_theme: "blue",
      data: formatted_mountains_investments?.sort(
        (a, b) => a?.investments?.position - b?.investments?.position
      ),
    },
    {
      background_color: "#D7E8F7",
      header_data: {
        icon: <SeaIcon />,
        location: "nad Morzem",
        href: "/investments/?category=sea",
      },
      theme: "navy",
      data: sea_investments,
    },
    {
      background_color: "#F1F7FC",
      header_data: {
        icon: <MazuryIcon />,
        location: "na Mazurach",
        href: "/investments/?category=lake",
      },
      theme: "navy",
      data: lake_investments,
    },
  ];
  return (
    <section className={mainClass}>
      {investments_data?.map((item, index) => (
        <div
          key={item?.data?.[0]?.id}
          className={`${mainClass}__item`}
          style={
            item?.background_image
              ? { backgroundImage: `url(${item?.background_image})` }
              : { backgroundColor: item?.background_color }
          }
        >
          <div className="container">
            <InvestmentsSlider
              data={item?.data}
              theme={{
                header_color: item?.theme,
                items_theme: item?.items_theme,
              }}
              header_data={{
                icon: item?.header_data?.icon,
                location: item?.header_data?.location,
                href: item?.header_data?.href,
              }}
              popup_form
            />
          </div>
        </div>
      ))}
    </section>
  );
};

export default Investments;
